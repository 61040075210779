// extracted by mini-css-extract-plugin
export var description = "SponsorsSection-module--description--bacf4";
export var image = "SponsorsSection-module--image--03f93";
export var opacityEnter = "SponsorsSection-module--opacityEnter--dfbd1";
export var opacityExit = "SponsorsSection-module--opacityExit--10912";
export var rollDown = "SponsorsSection-module--rollDown--aa979";
export var rollUp = "SponsorsSection-module--rollUp--b89db";
export var row = "SponsorsSection-module--row--e90d6";
export var slideInDown = "SponsorsSection-module--slideInDown--df799";
export var slideOutUp = "SponsorsSection-module--slideOutUp--201f5";
export var splashEnter = "SponsorsSection-module--splashEnter--7b255";
export var splashExit = "SponsorsSection-module--splashExit--f156b";