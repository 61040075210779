// extracted by mini-css-extract-plugin
export var box = "BoxWithTitle-module--box--1e8c8";
export var content = "BoxWithTitle-module--content--4921b";
export var opacityEnter = "BoxWithTitle-module--opacityEnter--ee6e3";
export var opacityExit = "BoxWithTitle-module--opacityExit--05382";
export var rollDown = "BoxWithTitle-module--rollDown--f0614";
export var rollUp = "BoxWithTitle-module--rollUp--8f74d";
export var slideInDown = "BoxWithTitle-module--slideInDown--69ed7";
export var slideOutUp = "BoxWithTitle-module--slideOutUp--ca063";
export var splashEnter = "BoxWithTitle-module--splashEnter--bede2";
export var splashExit = "BoxWithTitle-module--splashExit--b18a3";
export var title = "BoxWithTitle-module--title--40d21";