import React from 'react';
import Image from '@/components/elements/Image';
import BoxWithTitle from '@/components/elements/BoxWithTitle';
import { SponsorsSectionTypes } from './SponsorsSection.types';
import * as styles from './SponsorsSection.module.scss';

export default function SponsorsSection({ title, sponsors }: SponsorsSectionTypes) {
  if (!sponsors) return null;
  return (
    <BoxWithTitle title={title}>
      {sponsors.map(sponsor => (
        <div key={sponsor.content._uid} className={styles.row}>
          <Image data={sponsor.content.logo} fluid alt={sponsor.name} className={styles.image} />
          <p className={styles.description}>{sponsor.content.description}</p>
        </div>
      ))}
    </BoxWithTitle>
  );
}
