import React from 'react';
import * as styles from './BoxWithTitle.module.scss';

export default function BoxWithTitle({ title, children }: React.PropsWithChildren<{ title?: string }>) {
  return (
    <section className={styles.box}>
      <h3 className={`mb-7 ${styles.title}`}>{title}</h3>
      <div className={`box ${styles.content}`}>{children}</div>
    </section>
  );
}
